import {
    en_handicap, entre_tranche_dage,
    apprent_et_pasDePermis, avoir_28AnsMax_et_etudier_en_superieur,
    etudiant_et_boursier,
    avoir_65AnsMinimum_et_perte_dautonomie, vivreAParis_Avec_Sans_enfant_selon_sm, est_apprentis, pas_de_permis
} from './aidesCond/profil';
import {
    trois_enfants_de_moins_de_18,
    enfant_ayant_moins_de_6ans,
    enfant_deMoinsde_3an_et_2ans_auMoins_deTravail,
    enceinte_ou_enfant,
    enceinte,
    enfant_entre_tranche_dage
} from './aidesCond/enfants';
import { conjoint_aAuMoins_55ans } from './aidesCond/conjoint';
import moment from 'moment/moment';


const currentMonthIndex = moment().month();
export function getAllNewAides(data) {
    let newAides = {};
    if (enceinte_ou_enfant(data.statutPersonnel, data.statutPersonnel2, data.enfants))
        newAides.programme_malin = 20
    if (enceinte(data.statutPersonnel, data.statutPersonnel2))
        newAides.prime_de_naissance = 965
    if (en_handicap(data.statutPersonnel, data.statutPersonnel2)) {
        newAides.pch_transport = 5000
        newAides.pch_aménagement_logement = 10000
        newAides.pch_technique = 13200
    }
    if (entre_tranche_dage(data.age, 18, 25) && data.statutPersonnel.includes("Demandeur d’emploi") || data.statutPersonnel2.includes("Demandeur d’emploi"))
        newAides.service_civique = 580
    if (enfant_entre_tranche_dage(data.enfants, 6, 10) && (currentMonthIndex == 8 || currentMonthIndex == 9)) {
        newAides.ars = 392.05
    }
    if (enfant_entre_tranche_dage(data.enfants, 11, 14) && (currentMonthIndex == 9))
        newAides.ars_deux = 413.69
    if (enfant_entre_tranche_dage(data.enfants, 15, 18) && (currentMonthIndex == 9))
        newAides.ars_trois = 428.02
    if (trois_enfants_de_moins_de_18(data.enfants))
        newAides.carte_Famille_Nombreuse = 19
    if (enfant_ayant_moins_de_6ans(data.enfants))
        newAides.cmg = 239
    if (enfant_deMoinsde_3an_et_2ans_auMoins_deTravail(data.enfants, data.TravailleFranceApres2015))
        newAides.preParE = 663
    if (conjoint_aAuMoins_55ans(data.conjointAge))
        newAides.pension_réversion = 294
    // if (veuvage_auMoins_55ans_et_enZoneUE(data.nationalite, data.age, data.mesRevenusVal, data.etatLegal))
    //     newAides.pension_veuvage = 632
    if (entre_tranche_dage(data.age, 15, 25) && data.permisConduite === "Non")
        newAides.aide_Permis = 1
    if (entre_tranche_dage(data.age, 18, 25))
        newAides.depart_1825 = 250
    if (data.codePostal.startsWith("75") && data.formation == "Étudiant(e)" && data.bourse == "Oui")
        newAides.aide_aile = 900
    if (vivreAParis_Avec_Sans_enfant_selon_sm(data.codePostal, data.etatLegal, data.enfants)) {
        newAides.aides_sociale_de_paris_pour_les_familles = "763"
    }
    if (apprent_et_pasDePermis(data.statutPersonnel, data.permisConduite))
        newAides.aide_financement_permis_de_conduire_pprenti = "500"
    if (data.age > 60 && !data.aidesSocialesTab.includes('APA'))
        newAides.apa_domicile = 807
    if (data.bourse === "Oui" && data.bacmention === "Très bien")
        newAides.aide_au_merite = 100
    if (data.bourse === "Oui" && data.formation === "Master")
        newAides.aide_mobilite_master = 1000
    if ((data.statutPersonnel === "Étudiant(e)" ||
        data.statutPersonnel.includes("Étudiant(e)")) && avoir_28AnsMax_et_etudier_en_superieur(data.age, data.nationalite, data.formation, data.titreDeSejour, data.dateEnFrance))
        newAides.bourse_sur_criteres_sociaux = 100;
    if (etudiant_et_boursier(data.formation, data.bourse))
        newAides.repas_a_1eu_CROUS = "1"
    if (avoir_65AnsMinimum_et_perte_dautonomie(data.age, data.besoinSouventDaide))
        newAides.aide_au_portage_repas = "Éligible"
    if (data.nationalite === "Autre" && data.titreDeSejour5Ans == "Oui" && codePostalStarWith(data.codePostal))
        newAides.Naturalisation = "Éligible"
    if (data.demande_logement_social === "Oui")
        newAides.logement_social = "Éligible"
    if (data.enfants.filter(ef => ef.statut.includes("En situation de handicap")).length > 0
        && !data.aidesSocialesTab.includes('AEEH'))
        newAides.aeeh = 135
    if ((data.statutPersonnel.includes("Demandeur d’emploi") || data.statutPersonnel === "Demandeur d’emploi")
        && data.enfantsACharge === "Oui" && enfant_entre_tranche_dage(data.enfants, 1, 10)) {
        newAides.agepi = 400
    }

    if (data.inscrisPoleEmplois === "Non" && data.statutPersonnel == "Demandeur d’emploi"
        || data.statutPersonnel2.includes("Demandeur d’emploi")) {
        newAides.inscription_pole_sEmploi = "Éligible"
    }

    if (data.inscrisPoleEmplois === "Oui" && (data.statutPersonnel == "Demandeur d’emploi"
        || data.statutPersonnel2.includes("Demandeur d’emploi")) && data.nationalite == 'Français(e)' && data.age <= 65) {
        newAides.are = "Éligible"
    }

    if (data.age < 30 && (data.statutPersonnel == 'Apprenti(e)')
        && data.logementUser == 'Locataire'
        && !data.aidesSocialesTab.includes('Mobili Jeune')) {
        newAides.mobili_jeune = 100;
        // signataire_contrat_avec_entreprise_non_agricole_prive
    }

    if (data.impayer_de_loyer_ou_energie == 'Oui') {
        newAides.fsl = "Éligible"
    }

    if ((data.age >= 16 && data.age < 25 && data.statutPersonnel == "Demandeur d’emploi")
        || (data.age >= 25 && data.age < 30 && data.statutPersonnel2.includes("En situation de handicap"))) {
        newAides.contrat_engagement_jeune = "Éligible"
    }

    if ((data.age >= 18 && est_apprentis(data) &&
        data.signataire_contrat_avec_entreprise_non_agricole_prive == 'Oui'
        && pas_de_permis(data))) {
        newAides.aide_au_permis_pour_les_apprentis = 500
    }

    if (est_apprentis(data) && data.signataire_contrat_avec_entreprise_non_agricole_prive == 'Oui' &&
        data.trajet_de_plus_d1h_ou_70km == "Oui") {
        newAides.mobili_pass_demenagement = 2200
    }

    if (data.age < 26 && data.peparation_qualification_RNCP == 'Oui' &&
        data.formation_alternance_au_moins_1_an == "Oui") {
        newAides.carte_etudiant_ou_apprentis = "Éligible"
    }

    if ((data.statutPersonnel === "Étudiant(e)" ||
        data.statutPersonnel.includes("Étudiant(e)"))
        && data.bourse === 'non') {
        // data.age >= 35
        newAides.aed = 350
    }

    if (data.age <= 55 && data.etatLegal === 'Veuf(ve)') {
        newAides.allocation_de_veuvage = 662.70
    }

    if ((data.travaux_renovation_de_letat).includes('Oui') && data.type_de_propriete == 'Une maison') {
        newAides.primeRenov = 11000
    }

    if (data.rachat_ou_regroupement_de_credit == 'Oui') {
        newAides.rachat_ou_regroupement_de_credit = "Éligible"
    }

    if ((data.age >= 16 && data.age <= 30) &&
        (
            data.statutPersonnel === "Étudiant(e)" || data.statutPersonnel.includes("Étudiant(e)")
            || data.statutPersonnel === "Apprenti(e)" || data.statutPersonnel.includes("Apprenti(e)")
            || data.statutPersonnel === "Demandeur d’emploi" || data.statutPersonnel.includes("Demandeur d’emploi")
            || data.statutPersonnel === "Actif (salarié / indépendant)" || data.statutPersonnel.includes("Actif (salarié / indépendant)")
        )) {
        newAides.habitat_jeune = "Éligible"
    }
    return newAides;
}


function codePostalStarWith(code) {
    const codeList = ["O1", "03", "07", "09", "12", "15", "22", "26", "29", "31", "32", "35", "38", "42", "43", "44", "46", "49", "53", "56", "63", "65", "69", "72", "73", "74", "81", "82", "85", "92", "94"];
    let finded = false;

    for (let index = 0; index < codeList.length; index++) {
        if (code.startsWith(codeList[index])) {
            finded = true;
            break;
        }
    }
    return finded;
}