import { useParams } from "react-router-dom"
import { ResumeDeLaCommande } from "../GlobalComponents/OtherOnWebsite"
import axios from 'axios';



const Souscription = () => {
    const { typeCommande } = useParams();
    function handlePaye(e) {
        e.preventDefault()
        axios.post('/user/create-checkout-session', { souscriptionType: typeCommande })
            .then(res => {
                if (res.data.url) {
                    window.location = res.data.url
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    return <div className="souscription">
        <h1>France Assist souscription (offre a la tache) </h1>

        <div>
            <section className='sForm'>
                <button onClick={(event) => handlePaye(event)}>
                    Acceder a la page de paiement
                </button>
            </section>
            <ResumeDeLaCommande props={{ typeCommande }} />
        </div>
    </div>
}


const StripeWraper = () => {
    return <>
        <Souscription />
    </>
}
export default StripeWraper;