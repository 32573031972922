import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { getAllNewAides } from '../../helper/getters';

export function AideLayout({ props }) {
    const { aide, montant, delai, logo } = props;
    function getAideLogo() {
        if (logo == "caf")
            return "caf.jpeg"
        else if (logo === "mdph")
            return "mdph.jpeg"
        else if (logo === "aeeh")
            return "aeeh.png"
        else if (logo === "malin")
            return "malin.jpeg"
        else if (logo === "sncf")
            return "sncf.jpeg"
        else if (logo === "assure_retraite")
            return "assure_retraite.jpeg"
        else if (logo === "poleemploi")
            return "poleemploi.jpeg"
        else if (logo === "cnsa")
            return "apa.png"
        else if (logo === "crous")
            return "crous.jpeg"
        else if (logo === "logement_sociales")
            return "logement_sociales.jpeg"
        else if (logo == 'mobili_jeune')
            return "actionLogement.png"
        else if (logo === "paris")
            return "paris.png"
        else if (logo === "ancv")
            return "ancv.jpeg"
        else if (logo === "secu_routiere")
            return "secu_routiere.jpeg"
        else if (logo === "garantie_jeune")
            return "garantie_jeunes.jpg"
        else if (logo === "habitat_jeune")
            return "habitat_jeune.jpg"
        else if (logo === "anah")
            return "anah.png"
        else if (logo === "fa")
            return "fa.png"

    }
    return <div>
        <div>
            <img src={"/sources/aides/" + getAideLogo()} alt="aides" />
        </div>
        <div>
            <div>{aide} </div>
            <div>{montant}
                {
                    delai === "mois" ? "€/" + delai : delai
                }

            </div>
        </div>
    </div>
}
function NouvellesAides({ props }) {
    const { simulation } = props
    const data = simulation.data || simulation.newAides;
    const [aides, setAides] = useState({})

    useEffect(() => {
        // console.log(getAllNewAides(data))
        setAides(getAllNewAides(data))
    }, [])
    return (
        <>
            {
                data && aides.programme_malin &&
                <AideLayout props={{ aide: "Programme malin", montant: "20", delai: "mois", logo: "malin" }} />
            }
            {
                data && aides.prime_de_naissance &&
                <AideLayout props={{ aide: "Prime de naissance", montant: "965", delai: "mois", logo: "caf" }} />
            }
            {
                data && aides.pch_transport &&
                <AideLayout props={{ aide: "PCH  transport", montant: "5000€", delai: "", logo: "mdph" }} />
            }
            {
                data && aides.pch_aménagement_logement &&
                <AideLayout props={{ aide: "PCH aménagement logement", montant: "10000€", delai: "", logo: "mdph" }} />
            }
            {
                data && aides.pch_technique &&
                <AideLayout props={{ aide: "PCH technique", montant: "13200€", delai: "", logo: "mdph" }} />
            }
            {
                data && aides.service_civique &&
                <AideLayout props={{ aide: "Le service civique", montant: "580", delai: "mois", logo: "caf" }} />
            }
            {
                data && aides.ars &&
                <AideLayout props={{ aide: "ARS ou Allocation de rentrée scolaire", montant: "392,05€", delai: "", logo: "caf" }} />
            }
            {
                data && aides.ars_deux &&
                <AideLayout props={{ aide: "ARS ou Allocation de rentrée scolaire", montant: "413,69€", delai: "", logo: "caf" }} />
            }
            {
                data && aides.ars_trois &&
                <AideLayout props={{ aide: "ARS ou Allocation de rentrée scolaire", montant: "428,02€", delai: "", logo: "caf" }} />
            }
            {
                data && aides.carte_Famille_Nombreuse &&
                <AideLayout props={{ aide: "Carte Famille Nombreuse", montant: "19", delai: "mois", logo: "sncf" }} />
            }
            {
                data && aides.cmg &&
                <AideLayout props={{ aide: "CMG", montant: "239", delai: "mois", logo: "caf" }} />
            }
            {
                data && aides.preParE &&
                <AideLayout props={{ aide: "PreParE", montant: "663", delai: "mois", logo: "caf" }} />
            }
            {
                data && aides.pension_réversion &&
                <AideLayout props={{ aide: "Pension de réversion", montant: "294", delai: "mois", logo: "assure_retraite" }} />
            }
            {
                data && aides.pension_veuvage &&
                <AideLayout props={{ aide: "Pension veuvage", montant: "632", delai: "mois", logo: "sncf" }} />
            }
            {
                data && aides.aide_Permis &&
                <AideLayout props={{ aide: "Permis à 1€", montant: "Éligible", delai: "", logo: "secu_routiere" }} />
            }
            {
                data && aides.depart_1825 &&
                <AideLayout props={{ aide: "Départ 1825", montant: "250€", delai: "", logo: "ancv" }} />
            }
            {
                data && aides.aide_aile &&
                <AideLayout props={{ aide: "Aide Aile", montant: "900€", delai: "", logo: "crous" }} />
            }
            {
                data && aides.aides_sociale_de_paris_pour_les_familles &&
                <AideLayout props={{
                    aide: "Aides sociale de la ville de paris pour des familles (par le CAVSP)", montant: "763€",
                    delai: "", logo: "paris"
                }} />
            }
            {
                data && aides.aide_financement_permis_de_conduire_pprenti &&
                <AideLayout props={{
                    aide: "Aide financement permis de conduire Apprenti",
                    montant: "500€", delai: "", logo: "sncf"
                }} />
            }
            {
                data && aides.apa_domicile > 60 &&
                <AideLayout props={{
                    aide: "APA Domicile", montant: "807",
                    delai: "mois", logo: "cnsa"
                }} />
            }
            {
                data && aides.aide_au_merite &&
                <AideLayout props={{
                    aide: "Aide au merite", montant: "100",
                    delai: "mois", logo: "crous"
                }} />
            }
            {
                data && aides.aide_mobilite_master &&
                <AideLayout props={{
                    aide: "Aide mobilité master", montant: "1000€",
                    delai: "", logo: "crous"
                }} />
            }
            {
                data && aides.bourse_sur_criteres_sociaux &&
                <AideLayout props={{ aide: "Bourse sur critères sociaux", montant: "100", delai: "mois", logo: "crous" }} />
            }

            {
                data && aides.repas_a_1eu_CROUS &&
                <AideLayout props={{ aide: "Repas à 1€ CROUS", montant: "1", delai: "mois", logo: "crous" }} />
            }
            {
                data && aides.aide_au_portage_repas &&
                <AideLayout props={{ aide: "Aide au portage des repas", montant: "Éligible", delai: "", logo: "cnsa" }} />
            }
            {
                data && aides.Naturalisation &&
                <AideLayout props={{ aide: "Naturalisation", montant: "Éligible", delai: "", logo: "caf" }} />
            }
            {
                data && aides.logement_social &&
                <AideLayout props={{ aide: "Logement Social", montant: "Éligible", delai: "", logo: "logement_sociales" }} />
            }
            {
                data && aides.aeeh &&
                <AideLayout props={{
                    aide: "Allocation d’Éducation de l’Enfant Handicapé", montant: "135",
                    delai: "mois", logo: "aeeh"
                }} />
            }
            {
                data && aides.inscription_pole_sEmploi &&
                <AideLayout props={{ aide: "Inscription Pole-Emploi", montant: "", delai: "", logo: "poleemploi" }} />
            }
            {
                data && aides.are &&
                <AideLayout props={{ aide: "Allocation chômage d'aide au retour à l'emploi", montant: "", delai: "", logo: "poleemploi" }} />
            }
            {
                data && aides.mobili_jeune &&
                <AideLayout props={{
                    aide: "Mobili Jeune", montant: "100€",
                    delai: "mois", logo: "mobili_jeune"
                }} />
            }
            {
                data && aides.contrat_engagement_jeune &&
                <AideLayout props={{
                    aide: "Contrat d'engagement jeune", montant: "",
                    delai: "", logo: "garantie_jeune"
                }} />
            }
            {
                data && aides.fsl &&
                <AideLayout props={{
                    aide: "FSL,Accès logement", montant: "",
                    delai: "", logo: "logement_sociales"
                }} />
            }
            {
                data && aides.aide_au_permis_pour_les_apprentis &&
                <AideLayout props={{
                    aide: "Aide au permis de conduire pour les apprentis",
                    montant: "500€", delai: "", logo: "logement_sociales"
                }} />
            }
            {
                data && aides.mobili_pass_demenagement &&
                <AideLayout props={{
                    aide: "Aide Mobili-Pass pour le déménagement",
                    montant: "2200€", delai: "", logo: "logement_sociales"
                }} />
            }
            {
                data && aides.carte_etudiant_ou_apprentis &&
                <AideLayout props={{
                    aide: "La Carte Etudiant des Métiers / Carte nationale d’Apprentis",
                    montant: "", delai: "", logo: "logement_sociales"
                }} />
            }
            {
                data && aides.agepi && <div>
                    <div>
                        <img src="/sources/aides/poleemploi.jpeg" alt="aides" />
                    </div>
                    <div>
                        <div>AGEPI (Aide à la Garde d’enfants pour Parent Isolé) </div>
                        <div>400 €</div>
                    </div>
                </div>
            }
            {
                data && aides.aed &&
                <AideLayout props={{
                    aide: "Allocation Etudiant en Difficulté",
                    montant: aides.aed + "€", delai: "", logo: "logement_sociales"
                }} />
            }

            {
                data && aides.primeRenov &&
                <AideLayout props={{
                    aide: "Ma Prime Rénov ",
                    montant: aides.primeRenov + "€", delai: "", logo: "anah"
                }} />
            }

            {
                data && aides.allocation_de_veuvage &&
                <AideLayout props={{
                    aide: "Allocation veuvage",
                    montant: aides.allocation_de_veuvage, delai: "mois",
                    logo: "caf"
                }} />
            }

            {
                data && aides.rachat_ou_regroupement_de_credit &&
                <AideLayout props={{
                    aide: "RACHAT, REGROUPEMENT DE CRÉDIT",
                    montant: aides.rachat_ou_regroupement_de_credit, delai: "",
                    logo: "fa"
                }} />
            }

            {
                data && aides.habitat_jeune &&
                <AideLayout props={{
                    aide: "Habitat Jeunes", montant: "",
                    delai: "", logo: "habitat_jeune"
                }} />
            }



        </>
    )
}

export default NouvellesAides
