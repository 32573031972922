import { ResumeDeLaCommande } from '../GlobalComponents/OtherOnWebsite';
import { AuthForm } from '../RoutesSubComponents/auth';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AuthBanner from '../RoutesSubComponents/auth/banner';
import StealerAlert from '../GlobalComponents/StealerAlert';
import { withoutAuth } from '../hocs/withoutAuth';


let testKey = "pk_test_51Jc7k0DA83aSRojOOZcjKrvwv8CWgMruqYVHzqs29IGEDkb2meo0vGJsOaD4y6YnF8FMg7W6kCQlKW3bkGrrlalv00v0w9wTGu"
let liveKey = "pk_live_51Jc7k0DA83aSRojOcslcDv24eNXj3cOEx9doSf8oEgyAMg7LhjstuBYIT2WQSO2RaGrjYZpWcm61iT8fs03HqfXY00sIo4gz7d"

const stripePromise = loadStripe(process.env.NODE_ENV == "development" ? testKey : liveKey);

const Auth = ({ props }) => {
    const { authType } = props;

    return <>
        {/* <StealerAlert /> */}
        <div className="userAuth">
            <AuthBanner authType={authType} />
            {
                authType == 'inscription' ?
                    <ResumeDeLaCommande props={{ typeCommande: 'inscription' }} /> : ""
            }
            <Elements stripe={stripePromise}>
                <AuthForm authType={authType} />
            </Elements>
        </div>
    </>
}

export default withoutAuth(Auth);